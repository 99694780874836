import '@refinitiv-ui/efx-grid';
import '@refinitiv-ui/efx-grid/themes/halo/light';

import '@refinitiv-ui/elements/loader';
import '@refinitiv-ui/elements/sidebar-layout'
import '@refinitiv-ui/elements/button'
import '@refinitiv-ui/elements/collapse'
import '@refinitiv-ui/elements/combo-box'
import '@refinitiv-ui/elements/header'
import '@refinitiv-ui/elements/icon'
import '@refinitiv-ui/elements/multi-input'
import '@refinitiv-ui/elements/panel'
import '@refinitiv-ui/elements/password-field'
import '@refinitiv-ui/elements/overlay'
import '@refinitiv-ui/elements/select'
import '@refinitiv-ui/elements/slider'
import '@refinitiv-ui/elements/tab'
import '@refinitiv-ui/elements/tab-bar'
import '@refinitiv-ui/elements/text-field'
import '@refinitiv-ui/elements/toggle'
import '@refinitiv-ui/elements/tooltip'
import '@refinitiv-ui/elements/autosuggest'
import '@refinitiv-ui/elements/color-picker'
import '@refinitiv-ui/elements/datetime-picker'
import '@refinitiv-ui/elements/layout'
import '@refinitiv-ui/elements/tree-select'
import '@refinitiv-ui/elements/interactive-chart'
import '@refinitiv-ui/elements/notification';

import '@refinitiv-ui/halo-theme/dark/imports/native-elements';
import '@refinitiv-ui/elements/loader/themes/halo/light'
import '@refinitiv-ui/elements/sidebar-layout/themes/halo/light'
import '@refinitiv-ui/elements/button/themes/halo/light'
import '@refinitiv-ui/elements/collapse/themes/halo/light'
import '@refinitiv-ui/elements/combo-box/themes/halo/light'
import '@refinitiv-ui/elements/header/themes/halo/light'
import '@refinitiv-ui/elements/icon/themes/halo/light'
import '@refinitiv-ui/elements/multi-input/themes/halo/light'
import '@refinitiv-ui/elements/panel/themes/halo/light'
import '@refinitiv-ui/elements/password-field/themes/halo/light'
import '@refinitiv-ui/elements/overlay/themes/halo/light'
import '@refinitiv-ui/elements/select/themes/halo/light'
import '@refinitiv-ui/elements/slider/themes/halo/light'
import '@refinitiv-ui/elements/tab/themes/halo/light'
import '@refinitiv-ui/elements/tab-bar/themes/halo/light'
import '@refinitiv-ui/elements/text-field/themes/halo/light'
import '@refinitiv-ui/elements/toggle/themes/halo/light'
import '@refinitiv-ui/elements/tooltip/themes/halo/light'
import '@refinitiv-ui/elements/autosuggest/themes/halo/light'
import '@refinitiv-ui/elements/color-picker/themes/halo/light'
import '@refinitiv-ui/elements/datetime-picker/themes/halo/light'
import '@refinitiv-ui/elements/layout/themes/halo/light'
import '@refinitiv-ui/elements/tree-select/themes/halo/light'
import '@refinitiv-ui/elements/interactive-chart/themes/halo/light'
import '@refinitiv-ui/elements/notification/themes/halo/light';

import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
